import {
  Button, NavBar, Divider, Signature,
  Popup,
  Checkbox, CheckboxGroup,
  Image as VanImage,
  Dialog,

  Skeleton,
  SkeletonTitle,
  SkeletonImage,
  SkeletonAvatar,
  SkeletonParagraph,
} from 'vant';

// 1. 引入你需要的组件
// 2. 引入组件样式
import 'vant/lib/index.css';

export default function initVant(app) {
  app.use(Button);
  app.use(NavBar);
  app.use(Divider);
  app.use(Signature);
  app.use(Popup);
  app.use(Checkbox);
  app.use(CheckboxGroup);
  app.use(VanImage);
  app.use(Dialog);
  app.use(Skeleton);
  app.use(SkeletonTitle);
  app.use(SkeletonImage);
  app.use(SkeletonAvatar);
  app.use(SkeletonParagraph);
}
