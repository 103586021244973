<template>
  <!-- <nav>
    <router-link to="/">Home
      `<van-button>按钮</van-button>`
    </router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

.page {
  background-color: #f3f3f3;
}
</style>
