import axios from 'axios';

const instance = axios.create({
  baseURL: '',
  timeout: 1000,
  headers: { 'X-Custom-Header': '' },
});

const request = (params) => new Promise((resolve, reject) => {
  instance.request({
    params,
  }).then((response) => {
    resolve(response);
  })
    .catch((error) => {
      reject(error);
    });
});

export default request;
