import axios from 'axios';
import request from './request';

// 上传图片
export const uploadSignImage = (params) => {
  const url = 'https://www.chalston.com/api/labor/setSign';
  const formData = new FormData(); // 创建form对象
  formData.append('file', params.file); // 通过append向form对象添加数据
  formData.append('uid', params.uid);
  return axios({
    url,
    method: 'POST',
    data: formData,
  }).then((res) => res.data);
};

// 查询签署记录
export const findSign = (params) => {
  const url = 'https://www.chalston.com/api/labor/getSign';
  return axios({
    url,
    method: 'POST',
    data: params,
  }).then((res) => res.data);
};
