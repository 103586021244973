<template>
  <div class="sign-record page">
    <van-nav-bar :title="navTitle" style="margin-bottom: 15px;" />
    <van-skeleton title v-if="!fetchDone" :row="6" />
    <section class="main" v-if="fetchDone">
      <header class="title">卡尔AI用户推广计划</header>
      <van-divider :style="{ marginTop: 0 }" />
      <div>
        <template v-if="isSigned">
          <div class="s-title">签名信息（1/1)</div>
          <van-image width="100%" height="100" :src="signedUrl" />
        </template>
        <template v-else>
          <div class="s-title">签名信息（{{ unsignImage ? 1 : 0 }}/1)</div>
          <van-image width="100%" height="100%" v-if="unsignImage" :src="unsignImage" @click="onShow" />
          <section class="sign-tip-area" v-else @click.stop="onShow" @keydown="onShow">
            <div class="sign-tip-area_main ">请 签 名</div>
            <div class="sign-tip-area_small ">请在输入板上签字</div>
          </section>
        </template>

      </div>
      <div>
        <template v-if="isSigned">
          <div class="s-title">协议签署（3/3）</div>
          <div>
            <div v-for="(item, index) of agreeOptions" class="agree-item" :name="index" :key="index">
              <a target="_blank" :href="item.url"> {{ item.id }} {{ item.name }}
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="s-title">协议签署（{{ agreeChecked.length }}/3）</div>
          <div>
            <van-checkbox-group v-model="agreeChecked" shape="square">
              <van-checkbox class="agree-item" v-for="(item, index) of agreeOptions" :name="index" :key="index">
                <a target="_blank" :href="item.url"> {{ item.id }} {{ item.name }}
                </a>
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </template>

      </div>
      <footer class="btn-wrap">
        <van-button :loading="loading" v-if="!isSigned" type="primary" block @click="clickNext">下一步</van-button>
      </footer>

    </section>
    <van-popup v-model:show="show" position="left" :style="signContainerStyle">
      <SignCanvas @submit="onSubmitSign">
        <template v-slot:button>
          <van-button @click="onHide" style="flex:1" size="small" type="default">关闭</van-button>
        </template>
      </SignCanvas>
    </van-popup>

  </div>
</template>

<script>
// @ is an alias to /src
import { showDialog } from 'vant';
import SignCanvas from '@/components/SignCanvas.vue';

import * as SIGN_API from '@/api/sign-api';

export default {
  name: 'SignAgreementView',
  components: {
    SignCanvas,
  },
  data() {
    return {
      show: false,
      uid: '',
      agreeChecked: [],
      agreeOptions: [
        {
          id: 1,
          name: '《卡尔AI学员劳务协议》',
          url: 'http://chalston.com/wap/article/news_detail.html?id=12',
        },
        {
          id: 2,
          name: '《卡尔AI助教劳务协议》',
          url: 'http://chalston.com/wap/article/news_detail.html?id=13',
        },
        {
          id: 3,
          name: '《卡尔AI用户协议》',
          url: 'http://chalston.com/wap/article/news_detail.html?id=14',
        },
      ],
      unsignImage: '', // 未签署的图片
      signedUrl: '', // 已签署盖章图片url
      fetchDone: false,
      loading: false,
    };
  },
  computed: {
    isSigned() {
      return !!this.signedUrl;
    },
    navTitle() {
      return this.isSigned ? '签约记录' : '劳务协议签署';
    },
    signContainerStyle() {
      return {
        height: '100%', // 宽高单位互换
        width: '100%',
      };
    },
  },

  created() {
    const { uid } = this.$route.query;
    this.uid = uid;
    this.fetchData();
  },
  mounted() {
    // console.log('mounted');
  },
  methods: {
    fetchData() {
      const { debugTest } = this.$route.query;

      SIGN_API.findSign({
        uid: this.uid,
      }).then((res) => {
        if (debugTest) {
          return;
        }
        if (res?.data?.sign_url) {
          this.signedUrl = res.data.sign_url;
        }
      }).finally(() => {
        this.fetchDone = true;
      });
    },
    onShow() {
      this.show = true;
    },
    onHide() {
      this.show = false;
    },
    onClear() {
      this.unsignImage = '';
    },
    onSubmitSign(data) {
      this.unsignImage = data.image;
      this.show = false;
    },
    // 将base64转换为blob
    dataURLtoBlob(dataurl) {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // 将blob转换为file
    blobToFile(theBlob, fileName) {
      return new File([theBlob], fileName, { type: theBlob.type });
    },
    // 点击下一步
    clickNext() {
      const { uid } = this.$route.query;
      const { unsignImage } = this;

      if (!uid) {
        showDialog({
          title: '系统提示',
          message: '用户异常,请退出后再登陆了',
        }).then(() => {
          // on close
        });
        return;
      }
      if (!unsignImage) {
        showDialog({
          title: '系统提示',
          message: '未填写签名，请先签名',
        }).then(() => {
          // on close
        });
        return;
      }
      if (this.agreeChecked.length !== this.agreeOptions.length) {
        // console.log('请阅读并勾选相关协议');
        showDialog({
          title: '系统提示',
          message: '请阅读并勾选相关协议',
        }).then(() => {
          // on close
        });
        return;
      }

      const blob = this.dataURLtoBlob(unsignImage);
      const fileName = `${uid}__sign__${Date.now()}.png`;
      const file = this.blobToFile(blob, fileName);
      this.loading = true;
      SIGN_API.uploadSignImage({
        file,
        uid,
      }).then((res) => {
        // console.log(res, 'res');
        if (res.data?.url) {
          this.signedUrl = res.data.url;
          setTimeout(() => {
            window.location.href = 'http://chalston.com/wap/my/index.html';
          }, 300);
        } else {
          showDialog({
            title: '系统提示',
            message: res.msg,
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background-color: #fff;
  padding: 0 20px 20px;

  .title {
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .s-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  .agree-item {
    margin: 10px 10px;
  }
}

.page {
  background-color: #f3f3f3;
}

.sign-tip-area {

  background-color: rgba(242, 242, 242, 1);
  color: #D7D7D7;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;

  .sign-tip-area_main {
    font-size: 30px;
  }

  .sign-tip-area_small {
    font-size: 16px;
  }
}

.btn-wrap {
  margin: 20px 0;
}
</style>
