<template>
  <div class="signature-container">
    <div class="tool-bar">
      <slot name="button"></slot>
      <van-button @click="clearCanvas()" size="small" type="default">清除</van-button>
      <van-button @click="onConfirm" size="small" type="primary">确定</van-button>
    </div>
    <div class="wrap-canvas">
      <canvas ref="canvas" class="signature-canvas"></canvas>
    </div>
  </div>
</template>

<script>

const getBoundingRect = (element) => element?.getBoundingClientRect();

export default {
  name: 'SignCanvas',
  data() {
    return {
      ctx: null,
      isDrawing: false,
      lastX: 0,
      lastY: 0,
      testImage: '',
      canvasRect: null,
    };
  },
  mounted() {
    this.ctx = this.$refs.canvas.getContext('2d');
    this.$refs.canvas.width = window.innerWidth;
    this.$refs.canvas.height = window.innerHeight;

    this.$refs.canvas.addEventListener('mousedown', this.startDrawing);
    this.$refs.canvas.addEventListener('mousemove', this.draw);
    this.$refs.canvas.addEventListener('mouseup', this.stopDrawing);
    this.$refs.canvas.addEventListener('touchstart', this.startDrawing);
    this.$refs.canvas.addEventListener('touchmove', this.draw);
    this.$refs.canvas.addEventListener('touchend', this.stopDrawing);
  },
  methods: {
    startDrawing(e) {
      this.isDrawing = true;
      this.lastX = e.offsetX || e.touches[0].clientX;
      this.lastY = e.offsetY || e.touches[0].clientY;
      this.canvasRect = getBoundingRect(this.$refs.canvas);
    },
    draw(e) {
      if (!this.isDrawing) return;
      const { canvasRect } = this;
      // console.log(canvasRect, 'canvasRect');
      const x = (e.offsetX || e.touches[0].clientX) - (0 || 0);
      const y = (e.offsetY || e.touches[0].clientY) - (0 || 0);

      this.ctx.beginPath();

      this.ctx.lineWidth = 3;

      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(x, y);
      this.ctx.stroke();

      this.lastX = x;
      this.lastY = y;
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
    },
    saveSignature() {
      const dataURL = this.$refs.canvas.toDataURL();
      return dataURL;
    },
    async onConfirm() {
      const res = await this.drawRotatedImage();
      this.$emit('submit', {
        image: res.image,
      });
    },
    drawRotatedImage() {
      // 把图片正常化
      return new Promise(((resolve) => {
        if (this.isCanvasEmpty(this.$refs.canvas)) {
          resolve({ image: '' });
          return;
        }
        const imgView = new Image();
        imgView.src = this.saveSignature();
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const cutCoor = {
          sx: 0,
          sy: 0,
          ex: 0,
          ey: 0,
        };
        // 裁剪坐标
        imgView.onload = () => {
          const imgW = imgView.width;
          const imgH = imgView.height;
          const size = imgH;
          //   常量大小 = imgH;
          canvas.width = size * 2;
          canvas.height = size * 2;
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          context.translate(size, size);
          context.rotate((Math.PI / 2) * 3);
          context.drawImage(imgView, 0, 0);
          const imgData = context.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
          canvas.width = imgH;
          canvas.height = imgW;
          context.putImageData(imgData, 0, 0);
          resolve({ image: canvas.toDataURL('image/png') });
          // console.log(canvas.toDataURL('image/png'));
          // this.testImage = canvas.toDataURL('image/png');
        };
      }));
    },
    isCanvasEmpty(canvas) {
      const empty = document.createElement('canvas');
      empty.width = canvas.width;
      empty.height = canvas.height;
      // if (props.backgroundColor) {
      //   const emptyCtx = empty.getContext('2d');
      //   setCanvasBgColor(emptyCtx);
      // }
      return canvas.toDataURL() === empty.toDataURL();
    },
  },
};
</script>

<style scoped lang="scss">
.signature-container {
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  // padding: 50px 10px;
  box-sizing: border-box;
  background: #f3f3f3;

}

.wrap-canvas {
  width: 100%;
  height: 100%;
  touch-action: none;
  // margin-left: 100px;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}

.signature-canvas {
  width: 100%;
  height: 100%;
  touch-action: none;
  background-color: #fff;

}

.tool-bar {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 46%;
  left: -10rem;
  transform: rotate(90deg);

  .van-button {
    flex: 1;
    margin-left: 15px;
  }
}
</style>
